
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { getUnionApplyList, setUnionApplyStatus } from "@/api/request/studio";

//组件
@Component({
  name: "MemberReview",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = false;

  //列表请求参数
  private listQuery: any = {
    //页面数据
    page: 1,
    row: 20,
  };

  //创建
  created() {
    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getUnionApplyList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理通过
  private handlePass(row: any): void {
    //显示提示
    this.$confirm("确定通过博主 <" + row.nickname + "> ?", "警告", {
      confirmButtonText: this.$t("permission.confirm") as string,
      cancelButtonText: this.$t("permission.cancel") as string,
      type: "warning",
    })
      .then(async () => {
        //显示等待
        this.listLoading = true;

        //修改状态
        await setUnionApplyStatus({ id: row.userid, status: 2 });

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //处理拒绝
  private handleReject(row: any): void {
    //显示提示
    this.$confirm("确定拒接博主 <" + row.nickname + "> ?", "警告", {
      confirmButtonText: this.$t("permission.confirm") as string,
      cancelButtonText: this.$t("permission.cancel") as string,
      type: "warning",
    })
      .then(async () => {
        //显示等待
        this.listLoading = true;

        //修改状态
        await setUnionApplyStatus({ id: row.userid, status: 3 });

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
